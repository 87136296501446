import React, { useState, useEffect, useRef } from "react";
import "./bikeSequence.css";
import Videosource3 from "./assets/website header bike animation 8.mp4"

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("./assets/BikeSequence1", false, /\.(png|jpe?g|svg|webp)$/)
);

function Bikesequence() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const [videoOpacity, setVideoOpacity] = useState(1);
  const [showSmokeVideo, setShowSmokeVideo] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false); // Overlay control state

  const bikeContainerRef = useRef(null);
  const bike1Ref = useRef(null);
  const videoRef = useRef(null);
  const smokeVideoRef = useRef(null);

  // Update mobile view on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const bikeContainer = bikeContainerRef.current;

    if (isMobileView) {
      bikeContainer.style.height = "50vh";
    } else {
      bikeContainer.style.height = "300vh";

      const gearElement = bike1Ref.current;
      gearElement.style.position = "sticky";
      gearElement.style.top = "0";

      const handleScroll = () => {
        const gearContainerTop = bikeContainer.offsetTop;
        const gearContainerHeight = bikeContainer.offsetHeight;
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;
        const totalImages = images.length;
        const scrollableHeight = gearContainerHeight - windowHeight;
        const startScroll = gearContainerTop;
        const endScroll = gearContainerTop + scrollableHeight;

        if (scrollPosition >= startScroll && scrollPosition <= endScroll) {
          const progress = (scrollPosition - startScroll) / scrollableHeight;
          const imageIndex = Math.floor(progress * totalImages);
          setCurrentImageIndex(Math.min(Math.max(imageIndex, 0), totalImages - 1));

          // Show smoke video and overlay image when reaching the 30th image
          setShowSmokeVideo(imageIndex >= 50);
          setShowOverlay(imageIndex >= 40); // Control overlay visibility

          // Gradually reduce video opacity
          if (imageIndex === 0) {
            setVideoOpacity(1 - progress * 2);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isMobileView]);

  const mobileImageIndex = images.length - 1;

  return (
    <>
      <div className="bikeSequence-container" ref={bikeContainerRef}>
        <div className="bikeSequence" ref={bike1Ref}>
          {isMobileView ? (
            <img
              src={
                images[mobileImageIndex]?.default || images[mobileImageIndex]
              }
              className="bikeSequence-pic"
              alt={`bike ${mobileImageIndex}`}
            />
          ) : (
            <>
              {currentImageIndex === 0 && (
                <video
                  ref={videoRef}
                  src={Videosource3}
                  loop
                  autoPlay
                  muted
                  className="bikeSequence-video"
                  style={{ opacity: videoOpacity }}
                />
              )}
              <img
                src={
                  images[currentImageIndex]?.default ||
                  images[currentImageIndex]
                }
                className="bikeSequence-pic"
                alt={`bike ${currentImageIndex}`}
              />
              {showOverlay && (
                <img
                  src={require("./assets/Group 56.png")} // Ensure path is correct
                  className="overlay-image"
                  alt="Overlay"
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Bikesequence;
